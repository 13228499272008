export interface GetFileHistory {
    status: string;
    data: FileHistory;
    message: string;
}

export interface FileHistory {
    errorFileId: number;
    fileDescription: string;
    fileId: number;
    fileName: string;
    noOfErrors: number;
    noOfInvoiceErrors: number;
    noOfInvoices: number;
    noOfRecords: number;
    statusMessage: string;
    uploadedOn: string;
    uploadedBy: string;
    isFileDownloading: boolean;
    message: string;
    errorMessage : string;
}
export interface FileHistoryAPOB {
    UserID: string;
    Password: string;
    APOBAddress: string;
    State: string;
    EntityName: string;
    GSTIN: string;
}
export interface ErrorFileHistory {
    entityGSTIN: string;
    documentType: string;
    documentNo: string;
    documentDate: string;
    errorFileName: string;
}

export interface DeleteFileHistory {
    fileName: string;
    fileDescription: string;
    templateName: string;
    uploadedOn: any;
    deletedOn: any;
    deletedBy: string;
    fileId: number;
}

export class APOBSaveDataModel {
    filename: string;
    fileType: string;
    actualFile: any;
    transationId: number;
    uploadId: number;    
}
export interface FileTemplates {
    template: string;
    value: string;
    TransactionType: string;
    IsOverwritedatabuttonVisible: boolean;
    GenerateEinv: boolean;
    GenerateEwb: boolean;
    GenerateReturns: boolean;
}
export class CustomColumns {
    display: string;
    value: string;
}
export class MasterTypes {
    display: string;
    value: string;
}
export class MappingTable {
    StandardColumns: string;
    CustomColumn: string;
    MasterType : string;
    MasterColumn : string;
}

export interface ListOfMasterColumns {
    mastertype: string;
    mastercolumn: string[];
}
export interface MasterLookup {
    columnName: string;
    isMasters: string;
}
export class ListOfStandardTemplateColumns {
    SourceColumn : string = "";
    TemplateColumn: string;
    MasterType: string;
    MasterCode : string;
    MasterColumn : string = "";
    MasterColumnDisable : boolean = false;
    CustomColumnDisable : boolean = false;

}

export class TemplateMappingModel 
{
    SourceMetadata : Map<string, string>;
    DefinitionMap : TemplateMapDomainModel[];
    SourceMap : SourceMapDomainModel[];
    CustomTemplateName : string;
    SelectedTemplateID : string;
    OrganizationDetails : string;

}
export class TemplateMapMainModel {
    templateDataDomainModel : TemplateMappingModel;
    OrganizationID : number;
    CustomTemplateName : string;
    SelectedTemplateID : string;
}
export class SourceMapDomainModel {
        SourceColumn : string;
        TemplateColumn : string ;
        MasterType : string;
        MasterCode : string;
        MasterColumn : string;
        IsStatic : boolean;
        StaticCoordinates : string;
        ValuePattern : string;
        Script : string;
        StaticValue : [];
        TemplateIndex : number;
        DefinitionMap : TemplateMapDomainModel;
}
export class TemplateMapDomainModel 
{
    ColumnName : string;
    ValueType : string;
    ValuePattern :string;
    ValueEnum : any[];
    IsRequired : boolean;
    MaxLength : number;
     MinLength : number
    Precision : number;
    Scale  : number;
}
export interface UIFileTemplates {
    template: string;
    value: string;
    standardTemplate: boolean;
    enableDelete: boolean;
}
