import { Component, ElementRef, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { log } from 'console';
import { GetUploadFileService } from 'src/app/shared/services/get/get-srv-boew-upload/get-upload-file.service';
import { GlobalSrvConstantsService } from 'src/app/shared/services/global/global-constants/global-srv-constants.service';

@Component({
  selector: 'app-apob-attachments-dialog',
  templateUrl: './apob-attachments-dialog.component.html',
  styleUrls: ['./apob-attachments-dialog.component.css']
})
export class ApobAttachmentsDialogComponent implements OnInit {
  SampleData: string;

  constructor(public dialogRef: MatDialogRef<ApobAttachmentsDialogComponent>, private _snackBar: MatSnackBar, private _getUplaodFile: GetUploadFileService, private _fb: FormBuilder, @Inject(MAT_DIALOG_DATA) private data: any) { }
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
  @Output() filedata = new EventEmitter<any>();
  theEvent: any[] = [];
  TheTempEvent: any[] = [];
  writingFileNameDisabled = true;
  theAnotherTempEvent: any[] = [];
  IsfileUploadInProgress: boolean = false;
  file: any[] = [];
  fileName_1 = '';
  IsSaveButtonEnable = false;
  _dataTypeSelected: string = "";
  IsVisible = true;
  TransationID: number;
  AttachmentData: any[] = [];
  Type: string;
  DownLoadAllDisabled = true;
  selectedFile: File | null = null;
  selectedValue: string = '';
  selectedType: string = '';

  options = [
    { value: 'Rent', viewValue: 'Rent Aggrement' },
    { value: 'NOC', viewValue: 'NOC' },
  ];
  ngOnInit() {
    this.SampleData = this.data;
  }

  onFileSelected(event: any) {    
    this.theAnotherTempEvent = [];
    let files: string[] = [];
    let files_Names: string[] = [];
    this.IsfileUploadInProgress = true;
    this.writingFileNameDisabled = true;
    //this.fileName =[];

    if (event.target.files === null || event.target.files === 0) {
      this._snackBar.open('Please Select File', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
    }
    else if (event.target.files.length > 2) {
      this._snackBar.open('Maximum 5 files are allowed', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });

    }

    else if (this.AttachmentData.length > 0 && this.AttachmentData.some(row => row["selectedType"] === this._dataTypeSelected)) {
      this._snackBar.open('only One NOC and one Rent Pdfs are allowed', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']

      });
      this.IsfileUploadInProgress = false;
      return;
    }
    else if (!this.ValidateFilesextension(event.target.files)) {
      this._snackBar.open('Please Select Correct File Format', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
    }
    else {
      var uploadedFiles = event.target.files;
      if (uploadedFiles.length === 1)
        this.fileName_1 = uploadedFiles[0].name;
      else
        for (var file of uploadedFiles) {
          files_Names.push(file.name)
        }
      if (files_Names.length > 0)
        this.fileName_1 = files_Names.join(',');
      for (var file of uploadedFiles) {

        const fileTemp = file;
        const reader = new FileReader();
        var results = reader.readAsDataURL(fileTemp);
        reader.onload = () => {

          let object = { 'FILENAME': fileTemp.name, 'ACTUAL_FILE': reader.result, 'PDFType': this._dataTypeSelected };
          this.theAnotherTempEvent.push(object);
          files.push(fileTemp.name)

        };
        event.target.files = null

      }
    }
  }
  onFileChange(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }
  onTypeChange(type: string): void {
    this._dataTypeSelected = type;
  }

  TaxNoticeOperationSingleFile(action, element, type, actualSource) {
    this.IsVisible = true;
    if (actualSource == "CACHE") {
      if (action == 'Delete') {
        this.TheTempEvent.forEach((item, index2) => {
          if (item.FILENAME.includes(element.fileName)) {

            this.TheTempEvent.splice(index2, 1);
          }
        });
        this.AttachmentData.forEach((item, index2) => {
          if (item.fileName.includes(element.fileName)) {

            this.AttachmentData.splice(index2, 1);
          }
        });
        this.theAnotherTempEvent.forEach((item, index2) => {
          if (item.FILENAME.includes(element.fileName)) {

            this.theAnotherTempEvent.splice(index2, 1);
          }
        });
        this._dataTypeSelected = "";
        this.fileName_1 = '';
        this.IsfileUploadInProgress = false;
        this.IsVisible = false;
        this._snackBar.open('File deleted duccessfully', 'OK', {
          duration: GlobalSrvConstantsService.SNACKBARDURATION,
          panelClass: ['success-snackbar']
        });
      }
      else if (action == 'Download') {
        this._getUplaodFile.APOBOperation_SingleFile(action, 0, 0, ('APOB_TempFiles_' + type), element.fileName, "").subscribe(
          data => {
            const blob = new Blob([data], { type: data.type });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = element.fileName;
            anchor.href = url;
            anchor.click();
            this.IsVisible = false;
          })
      }
    }      
  }
  UploadFiles() {    
    this.IsVisible = true;
    let tempfiles: any[] = [];
    if (this.fileName_1 === null || this.fileName_1 === "" || this.fileName_1 === undefined || this.theAnotherTempEvent.length === 0) {
      this._snackBar.open('Please select the file ', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      this.IsVisible = false;
      return;
    }
    for (var event of this.theAnotherTempEvent) {
      event.FILENAME = event.FILENAME;
    }
    for (var event of this.theAnotherTempEvent) {
      this.TheTempEvent.push(event);
      let obj = { 'actualFile': event.ACTUAL_FILE, 'filename': event.FILENAME, 'fileType': this._dataTypeSelected, 'transationId': this.data.row.transationId }
      tempfiles.push(obj);

    }
    this.AddFileInExistingList();
    this._dataTypeSelected = "";
    this.fileName_1 = '';
    this.IsfileUploadInProgress = false;    
  }
  loadAttachmentData(TransationID) {    
    this.DownLoadAllDisabled = true;
    this._getUplaodFile.getAPOBAttachments(TransationID).subscribe
      (
        data => {
          if ('200'.localeCompare(data.status) === 0) {
            if (data.data.length > 0)     
            this.DownLoadAllDisabled = false;
            for (var obj of data.data) {
              let theObj = {
                'source': obj.source,
                'description': obj.description,
                'fileName': obj.fileName,
                'createdOn': obj.createdOn,
                'ActualSource': 'DB',
                'fileFolder': obj.fileFolder,
                'filenameWithNoticeId': obj.filenameWithNoticeId,
                'folderId': obj.folderId,
                'noticeDemandOrderID': obj.noticeDemandOrderID,
                'organizationId': obj.organizationId,
                'taxNoticeId': obj.taxNoticeId,
                'type': obj.type
              };
              this.AttachmentData.push(theObj);
            }
            this.IsVisible = false;
          }
          else {
            this.IsVisible = false;
          }
        },
        error => {
          this.IsVisible = false;
          this.DownLoadAllDisabled = true;
          let errorStatus;
          let errorMessage;
          if (error.error.message !== undefined) {
            errorStatus = error.error.status;
            errorMessage = error.error.message.substring(0, 90);
          } else {
            errorStatus = error.status;
            errorMessage = error.error;
          }
          this._snackBar.open('Error | Status: ' + errorStatus + ' | ' + errorMessage, 'OK', {
            duration: GlobalSrvConstantsService.SNACKBARDURATION,
            panelClass: ['danger-snackbar']
          });

        }
      );
  }
  AddFileInExistingList() {
    const d = new Date();
    var date = d.toLocaleDateString('pt-PT')
    for (var event of this.theAnotherTempEvent) {
      let theObj = {
        'selectedType': event.PDFType,
        'fileName': event.FILENAME.split('^')[0],
        'ActualSource': 'CACHE',
        'createdOn': d,
        'fileFolder': '',
        'filenameWithNoticeId': '',
        'folderId': '',
        'organizationId': '',
        'TransationId': this.TransationID,
      };
      this.AttachmentData.push(theObj);
    }
  }

  ClosePopup() {
    const searchValues = ["NOC", 'Rent'];
    const missingValues = searchValues.filter(value => !this.AttachmentData.some(row => row["selectedType"] === value));
    if (missingValues.length>=1) {
      this._snackBar.open('Both NOC and Rent Pdfs are mandatory', 'OK', {
        duration: GlobalSrvConstantsService.SNACKBARDURATION,
        panelClass: ['alert-snackbar']
      });
      return
    }
    this.theEvent = [];
    for (var fileinfo of this.TheTempEvent) {
      this.theEvent.push(fileinfo);
    }
    this.filedata.emit(this.theEvent)
    this.dialogRef.close({ 'data': this.theEvent, 'description': "desc" });
  }

  CancelUpload(){
    this.theAnotherTempEvent=[];
    if(this.fileName_1!== '' && this.fileName_1 !== undefined && this.fileName_1!== null){
     let uploadedfileNames = this.fileName_1.split(',');
     for(var filename of uploadedfileNames){
        this.TheTempEvent.forEach( (item, index2) => {
     if(item.FILENAME.includes(filename)){
       
       this.TheTempEvent.splice(index2,1);
     } 
   });
     }
     
    }
    this._dataTypeSelected = "";
     this.fileName_1 = '';
     this.IsfileUploadInProgress = false;
   }

  clearFile() {
    this.fileInput.nativeElement.value = '';
  }
  ValidateFilesextension(files): boolean {
    let IsAllFileValid = true;
    for (const file of this.file) {
    }
    var allowedExtensions = ["pdf", "xlsx", "docx", "xls", "zip"];
    for (const file of files) {
      let fileActualExtension = file.name.split('.').pop().trim().toLowerCase();
      if (!allowedExtensions.includes(fileActualExtension)) {
        IsAllFileValid = false;
        break;
      }
    }
    return IsAllFileValid;
  }

}
