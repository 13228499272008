import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APOBSaveDataModel, GetFileHistory } from  '../../../../shared/models/get/get-md-beow-upload/get-file-history';
import { GlobalApiService } from '../../global/global-api/global-api.service';
import { MasterSource } from '../../../Constants';
import { AppConfigService } from '../../common/app-config/app-config.service';
import { SearchUploadModel } from 'src/app/shared/models/get/get-md-global/boew-inv/get-boew-inv-search';
import { tempfiletoServer } from 'src/app/shared/models/post/pst-md-tax-notice/post-search-tax-notice';

@Injectable({
    providedIn: 'root'
})
export class GetUploadFileService {

    baseUrl = GlobalApiService.GLOBALAPIURL;
    oganizationDetail = GlobalApiService.ORGANIZATIONDATA;
    gstrServiceBaseUrl = AppConfigService.ApiGstrServiceUrl;
    constructor(private _httpClient: HttpClient) { }


    getFileHistory(pageSize, currentPage,filterValue,filename,date): Observable<any> {
        const searchFileModel: SearchUploadModel = {} as SearchUploadModel;
        searchFileModel.pageSize = pageSize;
        searchFileModel.currentPage=currentPage;
        searchFileModel.fileName=filename == '' ? null :filename;
        searchFileModel.uploadedOn=date == '' ? null : date;
        searchFileModel.filterModel =filterValue;
        return this._httpClient.post(
           this.baseUrl + '/bo/inv/uploadSearch' ,searchFileModel
           );
    }
    getFileHistoryAPOB(pageSize, currentPage,filterValue,filename,date): Observable<any> {
        const searchFileModel: SearchUploadModel = {} as SearchUploadModel;
        searchFileModel.pageSize = pageSize;
        searchFileModel.currentPage=currentPage;
        searchFileModel.fileName=filename == '' ? null :filename;
        searchFileModel.uploadedOn=date == '' ? null : date;
        searchFileModel.filterModel =filterValue;
        return this._httpClient.post(
           this.baseUrl + '/bo/inv/uploadSearchAPOB' ,searchFileModel
           );
    }
    getAPOBGridDetails(pageSize, currentPage,filterValue,filename,date): Observable<any> {
        const searchFileModel: SearchUploadModel = {} as SearchUploadModel;
        searchFileModel.pageSize = pageSize;
        searchFileModel.currentPage=currentPage;
        searchFileModel.fileName=filename == '' ? null :filename;
        searchFileModel.uploadedOn=date == '' ? null : date;
        searchFileModel.filterModel =filterValue;
        return this._httpClient.post(
        this.baseUrl + '/APOB/GridAPOBDetails' ,searchFileModel
           );
    }
    getErrorFileHistory(pageSize, currentPage,errorDocumentNumber, supplyType): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/getErrorFileHistory?PageSize=' + pageSize + '&CurrentPage=' + currentPage + '&DocumentNos=' + encodeURIComponent(errorDocumentNumber) + '&SupplyType=' + supplyType
        );
    }
    getDeleteFileHistory(pageSize, currentPage): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/getDeleteFileHistory?PageSize=' + pageSize + '&CurrentPage=' + currentPage
        );
    }
    getDeleteFileHistoryAPOB(pageSize, currentPage): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/APOB/getDeleteFileHistoryAPOB?PageSize=' + pageSize + '&CurrentPage=' + currentPage
        );
    }
    downloadErrorFileHistory(errorDocumentNumber,supplyType): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/downloadErrorFileHistory?DocumentNos=' + encodeURIComponent(errorDocumentNumber) + '&SupplyType=' + supplyType,
            {
             responseType: 'blob'
            }
            );
        }

    // Parameter API
    getFileData(getapi): Observable<any> {
        let params1 = new HttpParams().set('getapi', getapi);
        return this._httpClient.get(this.baseUrl + '/', { params: params1 });
    }

    getUploadTemplates(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/template/' + MasterSource.UI
        );
    }
    getUploadTemplatesAPOB(): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/bo/inv/templateAPOB/' + MasterSource.UI
        );
    }
    getReconTemplates(): Observable<any> {
        return this._httpClient.get(
            this.gstrServiceBaseUrl + '/gstr2a/recon/template/' + MasterSource.UI
        );
    }
    removeFileFromDataUpload(uploadedFileID): Observable<any> {
        return this._httpClient.post(
            this.baseUrl + '/bo/inv/removeFileFromDataUpload?uploadedFileID=' + uploadedFileID,
            { }
        );
    }
    APOBOperationSaveTempFile(APOBTemp:APOBSaveDataModel[]): Observable<any> {                   
        return this._httpClient.post(
          this.baseUrl + '/APOB/SaveAPOBTempFiles',APOBTemp);
        }
    SaveAPOBDetails(apobDetails:APOBSaveDataModel[]): Observable<any> {                      
            return this._httpClient.post(
              this.baseUrl + '/APOB/UpdateAPOBDetails',apobDetails);
            }
    getAPOBAttachments(id:number): Observable<any> {
        return this._httpClient.get(
            this.baseUrl + '/APOB/GetAPOBAttachmentDetails?id='+id);
        }  

    APOBOperation_SingleFile(opeartionType:string,fileId:number, taxNoticeid:number,folder:string,filename:string,type:string): Observable<any> {                       
        return this._httpClient.get(
        this.baseUrl + '/APOB/APOBOperation?opeartionType='+opeartionType+'&fileId='+fileId+'&taxNoticeid='+taxNoticeid+'&folder='+folder+'&filename='+filename+'&type='+type, {         
            responseType: 'blob'
        });
    }  
    DownloadNOCAggrementPDF(Base64String:APOBSaveDataModel[]): Observable<any> {                     
        return this._httpClient.post(
        this.baseUrl + '/APOB/DownloadPdfFromBase64',Base64String, {         
        });
    }    
}
